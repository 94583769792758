import { Tooltip } from "antd";
import {
  QuestionCircleFilled,
} from '@ant-design/icons';

export const rtfEditorToopltip = "If you want to paste text, use Ctrl + Shift + V (Windows) or Cmd + Shift + V (Mac) to paste without formatting. Then, adjust the format and add hyperlinks manually."

export const labelWithToolTip = (label: string, tooltip: string) => {
  return <Tooltip title={tooltip}><span>{label} </span>
    <QuestionCircleFilled />
  </Tooltip>
};
import { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import ClientBanner, { Banner } from "./ClientBanner";
import { PlusOutlined } from "@ant-design/icons";
import { Alert } from "antd";


export const ClientBannerList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false),
    [banners, setBanners] = useState<Banner[]>([{ id: 1 }]),
    canAddNewBanner: boolean = useMemo(() => {
      return banners?.length < 6
    }, [banners]),
    isPrevBannerSaved: boolean = useMemo(() => {
      return !banners?.find((item: Banner) => !item?.imageURL)
    }, [banners]),
    fetchBanners = useCallback(() => {
      setLoading(true)
      setBanners([{
        id: 1,
        imageURL: 'www.google.com',
        redirectLink: 'www.google.com'
      }])
      setLoading(false)
    }, []),
    deleteBanner = useCallback((id: number) => {
      setBanners((prev: Banner[]) => {
        return prev?.filter((item: Banner) => item?.id !== id)
      })
    }, []),
    updateBanner = useCallback((id: number, data: Banner) => {
      setBanners((prev: Banner[]) => {
        return prev.map((item: Banner) => {
          if (item?.id === id) {
            return data
          }
          return item
        })
      })
    }, []),
    addBanner = useCallback(() => {
      setBanners((prev: Banner[]) => {
        return [...prev, { id: prev?.length + 1 }]
      })
    }, []);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners])


  return <Loader loading={loading}>
    <div className="mt-8">
      Customize the member dashboard/app with up to
      <strong>&nbsp;6 marketing banners. </strong>
      These will appear prominently at the top of the screen. The sequence of the banners will be the same as here.
    </div>
    {banners?.map((item: Banner, index: number) => {
      return <ClientBanner
        key={index}
        banner={item}
        index={index}
        deleteBanner={deleteBanner}
        updateBanner={updateBanner}
      />
    })}
    {(canAddNewBanner && isPrevBannerSaved) && <div className="cta mt-24" onClick={addBanner}>
      <PlusOutlined /> Add another Banner
    </div>}
    {!isPrevBannerSaved && <Alert showIcon className="mt-24" type="info" message={"Save the previous banner to add another"} />}
  </Loader>
};
import { Col, Form, Image, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Dragger from 'antd/lib/upload/Dragger';
import { useCallback, useMemo, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { Button } from '../../../components/UI/Button/Button';
import { errorNotification } from '../../../components/UI/Toast/Toast';
import apiEndpoints from '../../../api/apiEndPoints';
import { errorHandler, getAccessToken } from '../../../utils/helper';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import ImgCrop from 'antd-img-crop';

export interface Banner {
  id: number;
  redirectLink?: string;
  imageURL?: string
}

interface Props {
  banner: Banner;
  index: number;
  deleteBanner: (id: number) => void;
  updateBanner: (id: number, data: Banner) => void;
}

const ClientBanner = ({ banner, index, deleteBanner, updateBanner }: Props) => {
  const [form] = useForm(),
    [btnLoading, setBtnLoading] = useState<boolean>(false),
    isUpdate: boolean = useMemo(() => {
      return !!banner?.imageURL
    }, [banner]),
    normFile = (e: any) => {
      if (Array.isArray(e)) {
        // console.log(e);
        return e;
      }
      return e?.fileList?.[0]?.response?.url;
    },
    props = {
      name: 'image',
      accept: '.png, .svg, .jpg, .jpeg, .webp',
      multiple: false,
      action: apiEndpoints.imageUpload,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
      maxCount: 1,
      onChange(info: any) {
        const { status } = info.file;
        if (status === 'error') {
          errorNotification(info?.file?.response?.error || 'Error : Please recheck the image and try again');
        }
      },
    },
    submitHandler = useCallback(() => {
      setBtnLoading(true)
      form.validateFields()
        .then((values) => {
          updateBanner(banner.id, { ...values, id: banner.id })
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => {
          setBtnLoading(false)
        })
    }, [banner, updateBanner]);

  return (
    <Loader>
      <Form
        form={form} initialValues={banner}>
        <div className="title" style={{ marginBottom: '12px' }}>Client Banner {index + 1} </div>
        <Form.Item
          name={'imageURL'}
          valuePropName="image"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          validateTrigger={['onBlur']}
          getValueFromEvent={normFile}
          rules={[
            { required: true, message: "Please upload an image for this banner" },
          ]}
          label="Upload Image"
        >
          {/* <ImgCrop aspect={6}> */}
            <Dragger {...props} listType="picture">
              <p className="ant-upload-drag-icon" style={{ marginTop: "24px" }}>
                <Image src={form.getFieldValue('imageURL')} preview={false} height={96} />
              </p>
              <p className="ant-upload-text" style={{ marginBottom: "24px" }}>
                Drag and drop your banner here
                <br /> or click here to upload
                <br />
                <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
                  Recommended size - 1080*240px
                </span>
              </p>
            </Dragger>
          {/* </ImgCrop> */}
        </Form.Item>

        <Form.Item
          label={<span>Redirect Link <Tooltip message="Banner would redirect to the link added here" /></span>}
          labelCol={{ span: 24 }}
          name="redirectLink"
          validateFirst
          className="label margin-top"
        >
          <Input />
        </Form.Item>

        <Row gutter={16} justify="start">
          <Col>
            <Button type="primary" loading={btnLoading} onClick={submitHandler}>Save</Button>
          </Col>
          {isUpdate && <Col>
            <Button type="secondary" loading={btnLoading} onClick={() => { banner?.id && deleteBanner(banner?.id) }}>Delete</Button>
          </Col>}
        </Row>
      </Form>
    </Loader>);
};

export default ClientBanner;

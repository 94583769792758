import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { FALLBACK_IMAGE } from '../../../constants/types';
import { Button } from '../Button/Button';
import TableComponent from '../Table/TableComponent';
import './_custommodal.scss';
import { errorNotification, successNotification } from '../Toast/Toast';
import BulbOn from '../../../assets/icons/bulb-on-outline.svg';
import { getSuggestion, getTitle } from './helperTexts';
import { errorHandler } from '../../../utils/helper';

interface Props {
  isModalVisible: boolean;
  modalTitle: string;
  cancelHandler: () => void;
  confirmHandler?: (payload?: object) => void;
  type: string;
  data?: any;
  okModal?: boolean;
  buttonLoading?: boolean;
  primaryButtonHandler?: any;
  secondaryButtonHandler?: () => void;
}

const createMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

const Delete = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to delete {props.data.entries} {props.data.object}(s)?
      </div>
    </>
  );
};

const BulkDelete = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <TableComponent
          columns={[
            {
              title: 'Employee ID',
              dataIndex: ['organisation', 'employee_id'],
              key: 'employee_id',
            },
            {
              title: 'Name',
              dataIndex: 'full_name',
              key: 'full_name',
            },
            {
              title: 'Date of Leaving',
              dataIndex: 'date_of_leaving',
              key: 'date_of_leaving',
              render: (text: any, data: any) => (
                <>
                  &nbsp;
                  <Form.Item
                    name={['date_of_leaving', `${data?.uuid}`]}
                    rules={[{ required: true, message: 'Please select date of leaving' }]}
                  >
                    <DatePicker
                      disabledDate={(current: any) => current.isAfter(moment())}
                      format="DD-MMM-YYYY"
                      suffixIcon
                      allowClear
                    />
                  </Form.Item>
                </>
              ),
            },
          ]}
          dataSource={props?.data}
          setRowKey="id"
          hasPaging={false}
        />
        <div className="mt-32" />
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to delete the above listed members?
      </div>
    </>
  );
};

const GenericConfirmation = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; {props?.data?.message}
      </div>
    </>
  );
};

const GenericTable = (props: any) => {
  return (
    <>
      <div className="modal-title">
        {props?.data?.message}
        <div className="mt-32">{props?.data?.table}</div>
      </div>
    </>
  );
};

const ApprovalModal = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'green' }} />
        &nbsp;&nbsp; Are you sure you want to add {props.data.entries} member(s)?
      </div>
    </>
  );
};

const CancelModal = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to cancel adding the entry?
      </div>
    </>
  );
};

const ConfirmLeaving = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'red' }} />
        &nbsp;&nbsp; Are you sure you want to leave the page? All your unsaved progress will be lost.
      </div>
    </>
  );
};

const AdminRole = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'green' }} />
        &nbsp;&nbsp; Are you sure you want to {props?.data.type}{' '}
        <span className="text-green">{props?.data?.full_name}</span> as organisation admin?
      </div>
    </>
  );
};

const AddModal = (props: any) => {
  return (
    <>
      <div className="modal-title">
        <InfoCircleOutlined style={{ color: 'green' }} />
        &nbsp;&nbsp; Are you sure you want to update {props.data.entries || 'the'} {props?.data?.object || 'member(s)'}?
      </div>
      <Row justify="space-around">
        {props?.data?.totalEmployees >= 0 && (
          <Col span={8}>
            <div className="modal-container">
              <div className="subtitle">Total Employees</div>
              <p className="modal-stats">{props?.data?.totalEmployees}</p>
            </div>
          </Col>
        )}
        {props?.data?.entries >= 0 && (
          <Col span={8}>
            <div className="modal-container">
              <div className="subtitle">Total Lives</div>
              <p className="modal-stats">{props?.data?.entries}</p>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

const BenefitsModal = (props: any) => {
  return (
    <>
      <Image preview={false} width={96} height={96} src={props?.data?.image} fallback={FALLBACK_IMAGE} />,
      <p className="margin-top">{props.data.benefit_description}</p>
    </>
  );
};

export const BulkMemberDeletionModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
}) => {
  const [form] = useForm(),
    deleteData = () => {
      form.validateFields().then((values) => {
        data.setDeleteData(
          data.dataSource.map((item: any, index: any) => {
            return {
              member_id: item?.uuid,
              date_of_leaving: form?.getFieldValue(['date_of_leaving', `${item?.uuid}`])
                ? moment(form?.getFieldValue(['date_of_leaving', `${item?.uuid}`])).format('DD-MM-YYYY')
                : form?.getFieldValue(['date_of_leaving', `${item?.uuid}`]),
            };
          })
        );
      });
    };

  useEffect(() => {
    if (data.deleteData?.[0]?.date_of_leaving) confirmHandler && confirmHandler();
  }, [data.deleteData]);

  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler}>
      <Form form={form}>
        <BulkDelete data={data.dataSource} />
      </Form>
      <Divider />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={deleteData}>
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const CustomModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  type,
  modalTitle,
  data,
  okModal = false,
  buttonLoading,
}) => {
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler}>
      {type === 'deletion' && <Delete data={data} />}
      {type === 'bulk-deletion' && <BulkDelete data={data} />}
      {type === 'addition' && <AddModal data={data} />}
      {type === 'generic-confirmaion' && <GenericConfirmation data={data} />}
      {type === 'generic-table' && <GenericTable data={data} />}
      {type === 'approval' && <ApprovalModal data={data} />}
      {type === 'benefits' && <BenefitsModal data={data} />}
      {type === 'cancel' && <CancelModal data={data} />}
      {type === 'adminrole' && <AdminRole data={data} />}
      {type === 'confirmleaving' && <ConfirmLeaving data={data} />}
      <Divider />

      {okModal ? (
        <Row gutter={16} className="modal-footer" justify="end">
          <Button type="primary" onClick={confirmHandler}>
            Close
          </Button>
        </Row>
      ) : (
        <Row gutter={16} className="modal-footer">
          <Col>
            <Button type="secondary" onClick={cancelHandler}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={buttonLoading} onClick={confirmHandler}>
              Confirm
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export const EndorsementSelectionModal: React.FC<Props> = ({
  isModalVisible,
  primaryButtonHandler,
  secondaryButtonHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
}) => {
  const [value, setValue] = useState('SELECT' as any),
    onChange = (e: any) => {
      setValue(e.target.value);
    },
    submitHandler = () => {
      if (value === 'SELECT') {
        primaryButtonHandler && primaryButtonHandler();
      } else {
        secondaryButtonHandler && secondaryButtonHandler();
      }
    };

  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <GenericTable data={data} />
      <Divider />
      <div className="mt-24" />
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={'SELECT'}>Select dependents of the selected members and proceed</Radio>
        <Radio value={'DESELECT'}>Deselect self with unselected dependents and proceed</Radio>
      </Radio.Group>
      <div className="mt-24" />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={submitHandler}>
            Proceed
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const UserDeletionModal: React.FC<Props> = ({
  isModalVisible,
  primaryButtonHandler,
  secondaryButtonHandler,
  cancelHandler,
  modalTitle,
  data,
  type,
}) => {
  const [listOfClaimsManagers, setListOfClaimsManagers] = useState([]),
    [listOfPolicyManagers, setListOfPolicyManagers] = useState([]),
    [userData, setUserData] = useState({} as any),
    [listOfCSA, setListOfCSA] = useState([]),
    submitHandler = () => {
      form.validateFields().then((values) => {
        // console.log(values);
        // console.log(data?.id);
        // return;
        setButtonLoading(true);
        api.user_management
          .moveUserClients({ user_id: data?.id, relationship_manager_data: values })
          .then((response) => {
            primaryButtonHandler && primaryButtonHandler();
            setButtonLoading(false);
          })
          .catch((error) => {
            setButtonLoading(false);
          });
      });
    },
    [loader, setLoader] = useState(true),
    [rmLoader, setRmLoader] = useState({
      claim_manager: false,
      policy_manager: false,
      customer_success_associate: false,
    }),
    [buttonLoading, setButtonLoading] = useState(false),
    { Option } = Select,
    [form] = useForm(),
    fetchRelationshipManagers = async (value: string, setFunction: any) => {
      setRmLoader((prevState: any) => ({ ...prevState, [value]: true }));
      api.clientaddition
        .userList(value)
        .then((response) => {
          setFunction(response.data?.filter((user: any) => user?.user !== data?.id));
          setRmLoader((prevState: any) => ({ ...prevState, [value]: false }));
        })
        .catch((error) => {
          setRmLoader((prevState: any) => ({ ...prevState, [value]: false }));
        });
    },
    fetchUserDetails = () => {
      api.user_management
        .checkUserRoles(data?.id)
        .then((response) => {
          setUserData(response);
          setLoader(false);
          if (response?.client_count > 0) {
            fetchRelationshipManagers('claim_manager', setListOfClaimsManagers);
            fetchRelationshipManagers('policy_manager', setListOfPolicyManagers);
            fetchRelationshipManagers('customer_success_associate', setListOfCSA);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    };

  useEffect(() => {
    if (isModalVisible && type === 'user') fetchUserDetails();
    else if (isModalVisible && type === 'role') {
      setLoader(false);
      if (data?.additionalData?.find((item: any) => item?.name === 'policy_manager' && item?.client_count > 0))
        fetchRelationshipManagers('policy_manager', setListOfPolicyManagers);
      if (data?.additionalData?.find((item: any) => item?.name === 'claim_manager' && item?.client_count > 0))
        fetchRelationshipManagers('claim_manager', setListOfClaimsManagers);
      if (
        data?.additionalData?.find((item: any) => item?.name === 'customer_success_associate' && item?.client_count > 0)
      )
        fetchRelationshipManagers('customer_success_associate', setListOfCSA);
    } else if (!isModalVisible) {
      setListOfCSA([]);
      setListOfClaimsManagers([]);
      setListOfPolicyManagers([]);
      setUserData({} as any);
      setButtonLoading(false);
      form.resetFields();
    }
  }, [isModalVisible]);

  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <Spin spinning={loader}>
        {(userData?.client_count === 0 || (!userData?.client_count && type === 'user')) && (
          <>
            Are you sure you want to delete <span style={{ fontWeight: 600 }}>{data?.first_name}</span> from the system?
          </>
        )}
        {!loader &&
          (userData?.client_count > 0 || data?.additionalData?.filter((item: any) => item?.client_count > 0)) && (
            <>
              There {userData?.client_count === 1 ? 'is' : 'are'}{' '}
              <span style={{ fontWeight: 600 }}>{userData?.client_count || 'some'}</span> client
              {userData?.client_count === 1 ? '' : 's'} assigned to{' '}
              <span style={{ fontWeight: 600 }}>{data?.first_name}</span>, please select new users to assign to these
              clients
              <Divider />
              <div className="mt-24" />
              {type === 'user' && (
                <Form form={form} name="rm-details" scrollToFirstError={true}>
                  {data?.roles?.find((item: any) => item?.name === 'claim_manager') && (
                    <Form.Item
                      label={`Claims Manager`}
                      labelCol={{ span: 24 }}
                      name="claim_manager_id"
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: 'Please select the assigned Claims Manager!' }]}
                    >
                      <Select
                        loading={rmLoader?.claim_manager}
                        showSearch
                        placeholder="Select a Claim Manager"
                        filterOption={(inputValue: any, option: any) =>
                          option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {listOfClaimsManagers.map((data: any) => {
                          return (
                            <Option key={data.id} value={data.id}>
                              {data.full_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  {data?.roles?.find((item: any) => item?.name === 'policy_manager') && (
                    <Form.Item
                      label={`Policy Manager`}
                      labelCol={{ span: 24 }}
                      name="policy_manager_id"
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: 'Please select the assigned Policy Manager!' }]}
                    >
                      <Select
                        loading={rmLoader?.policy_manager}
                        showSearch
                        placeholder="Select a Policy Manager"
                        filterOption={(inputValue: any, option: any) =>
                          option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {listOfPolicyManagers.map((data: any) => {
                          return (
                            <Option key={data.id} value={data.id}>
                              {data.full_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  {data?.roles?.find((item: any) => item?.name === 'customer_success_associate') && (
                    <Form.Item
                      label={`Customer Success Associate`}
                      labelCol={{ span: 24 }}
                      name="customer_success_associate_id"
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: 'Please select the assigned CSA!' }]}
                    >
                      <Select
                        loading={rmLoader?.customer_success_associate}
                        showSearch
                        placeholder="Select a CSA"
                        filterOption={(inputValue: any, option: any) =>
                          option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {listOfCSA?.map((data: any) => {
                          return (
                            <Option key={data.id} value={data.id}>
                              {data.full_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Form>
              )}
              {type === 'role' && (
                <Form form={form} name="rm-details" scrollToFirstError={true}>
                  {data?.additionalData?.find(
                    (item: any) => item?.name === 'claim_manager' && item?.client_count > 0
                  ) && (
                      <Form.Item
                        label={`Claims Manager (${data?.additionalData?.find((item: any) => item?.name === 'claim_manager')?.client_count
                          })`}
                        labelCol={{ span: 24 }}
                        name="claim_manager_id"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: 'Please select the assigned Claims Manager!' }]}
                      >
                        <Select
                          loading={rmLoader?.claim_manager}
                          showSearch
                          placeholder="Select a Claim Manager"
                          filterOption={(inputValue: any, option: any) =>
                            option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                          }
                        >
                          {listOfClaimsManagers.map((data: any) => {
                            return (
                              <Option key={data.id} value={data.id}>
                                {data.full_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}

                  {data?.additionalData?.find(
                    (item: any) => item?.name === 'policy_manager' && item?.client_count > 0
                  ) && (
                      <Form.Item
                        label={`Policy Manager (${data?.additionalData?.find((item: any) => item?.name === 'policy_manager')?.client_count
                          })`}
                        labelCol={{ span: 24 }}
                        name="policy_manager_id"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: 'Please select the assigned Policy Manager!' }]}
                      >
                        <Select
                          loading={rmLoader?.policy_manager}
                          showSearch
                          placeholder="Select a Policy Manager"
                          filterOption={(inputValue: any, option: any) =>
                            option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                          }
                        >
                          {listOfPolicyManagers.map((data: any) => {
                            return (
                              <Option key={data.id} value={data.id}>
                                {data.full_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}

                  {data?.additionalData?.find(
                    (item: any) => item?.name === 'customer_success_associate' && item?.client_count > 0
                  ) && (
                      <Form.Item
                        // label="Customer Success Associate"
                        label={`Customer Success Associate (${data?.additionalData?.find((item: any) => item?.name === 'customer_success_associate')
                          ?.client_count
                          })`}
                        labelCol={{ span: 24 }}
                        name="customer_success_associate_id"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: 'Please select the assigned CSA!' }]}
                      >
                        <Select
                          loading={rmLoader?.customer_success_associate}
                          showSearch
                          placeholder="Select a CSA"
                          filterOption={(inputValue: any, option: any) =>
                            option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                          }
                        >
                          {listOfCSA?.map((data: any) => {
                            return (
                              <Option key={data.id} value={data.id}>
                                {data.full_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                </Form>
              )}
            </>
          )}
        <div className="mt-24" />
        <Row gutter={16} className="modal-footer">
          <Col>
            <Button type="secondary" onClick={cancelHandler}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={buttonLoading} onClick={submitHandler}>
              Proceed
            </Button>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export const MoveBatchModal: React.FC<Props> = ({
  isModalVisible,
  primaryButtonHandler,
  cancelHandler,
  modalTitle,
  data,
  buttonLoading,
}) => {
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <GenericTable data={data} />
      <Divider />
      <div className="mt-24" />
      <Row gutter={16} className="modal-footer">
        <Col>
          <Button type="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" loading={buttonLoading} onClick={primaryButtonHandler}>
            Move
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const EndorsementPremiumConfirmationModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  data,
  type,
  buttonLoading,
}) => {
  const [insurerAmount, setInsurerAmount] = useState<string>(''),
    [remarks, setRemarks] = useState<string>(''),
    [amountMatch, setAmountMatch] = useState<boolean>(true),
    [form] = useForm(),
    [isSubmitting, setIsSubmitting] = useState<boolean>(false),
    isModification = type === 'MODIFICATION',
    isDeletion = type === 'DELETION',
    getAmount = () => {
      if (isDeletion) return data?.refund;
      return data?.premium ?? 0;
    },
    onSubmitHandler = () => {
      setIsSubmitting(true);
      form
        .validateFields()
        .then((values: { remarks: string; insurerAmount: string }) => {
          const regex = /^[+-]?\d+(\.\d+)?$/;
          if (!amountMatch) {
            if (!regex.test(values.insurerAmount)) {
              setAmountMatch(false);
              setIsSubmitting(false);
              errorNotification('Please enter a valid amount');
              return;
            }
          }
          const payload = {
            remarks: values.remarks,
            amount: parseFloat(values.insurerAmount ?? getAmount()),
          };
          confirmHandler && confirmHandler(payload);
          setIsSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          setAmountMatch(false);
          setIsSubmitting(false);
          console.log('error:', error);
        });
    },
    resetForm = () => {
      form.resetFields();
      setAmountMatch(true);
      setInsurerAmount('');
      setRemarks('');
    };

  return (
    <Modal
      title="Verify Details"
      visible={isModalVisible}
      footer={null}
      onCancel={cancelHandler}
      destroyOnClose
      className="endorsement-premium-modal"
    >
      <Form form={form} className="modal-content" layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <div className="detail-box">
              <div className="subtitle">Total Lives</div>
              <div className="value">{data?.count?.toString().padStart(2, '0') || '01'}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-box">
              <div className="subtitle">{getTitle(isModification, isDeletion)}</div>
              <div className="value">₹{getAmount()?.toLocaleString('en-IN') || '0'}</div>
            </div>
          </Col>
        </Row>
        {isModification && (
          <Row gutter={16}>
            <Col span={12}>
              <div className="detail-box">
                <div className="subtitle">Total Old Premium</div>
                <div className="value">₹{data?.old_premium?.toLocaleString('en-IN') || '0'}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="detail-box">
                <div className="subtitle">Change in Premium</div>
                <div className="value">₹{(data?.premium - (data?.old_premium || 0)).toLocaleString('en-IN')}</div>
              </div>
            </Col>
          </Row>
        )}
        {/* <Alert message="Your actions will have affect on client's CD Balance." banner type='info' className="info-box" />
        <div className="confirm-calculated">
          {getQuestion(isModification, isDeletion)}
        </div> */}
        {/* <Form.Item className="radio-group" initialValue={true} name="amountMatch" rules={[{ required: true, message: "Please select an option!" }]}>
          <label>
            <input
              type="radio"
              name="amountMatch"
              value="yes"
              checked={amountMatch === true}
              onChange={() => setAmountMatch(true)}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="amountMatch"
              value="no"
              checked={amountMatch === false}
              onChange={() => setAmountMatch(false)}
            />
            No
          </label>
        </Form.Item> */}
        <>
          <div className="suggestion-box">
            <div className="suggestion-icon">
              <img src={BulbOn} alt="bulb" />
            </div>
            <div className="suggestion-text">
              <span className="text-bold">Suggestion:</span> {getSuggestion(isModification, isDeletion)}
            </div>
          </div>

          {/* <Form.Item
              label={getInsurerInputLabel(isModification, isDeletion)}
              name="insurerAmount"
              rules={[{ required: true, message: "Please enter amount!" }]}
            >
              <Input
                placeholder="Enter Amount"
                value={insurerAmount}
                onChange={(e) => setInsurerAmount(e.target.value)}
              />
            </Form.Item> */}
        </>
        {/* <Form.Item
          label="Internal Remarks"
          name="remarks"
        >
          <Input.TextArea
            rows={1}
            placeholder="Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Form.Item> */}
        <Row gutter={16} className="modal-footer">
          <Col>
            <Button
              type="secondary"
              onClick={() => {
                cancelHandler();
                resetForm();
              }}
            >
              CANCEL
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={buttonLoading}
              onClick={onSubmitHandler}
              disabled={amountMatch === null || isSubmitting}
            >
              CONFIRM
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const PricingLogicModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalTitle,
  data,
  // loading,
}) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  return (
    <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <Row gutter={16} className="margin-top">
        <Col span={8}>
          <Statistic
            // loading={loading}
            title={
              <span>
                Inception Payment Frequency
                <Tooltip title="CD Balance is the amount of money that is available in the client's account.">
                  <InfoCircleOutlined className="ml-8" />
                </Tooltip>
              </span>
            }
            value={data?.cd_balance || '-'}
          />
        </Col>
        <Col span={8}>
          <Statistic
            // loading={loading}
            title={
              <span>
                Endorsement Payment Frequency
                <Tooltip title="Net Premium collected for the product">
                  <InfoCircleOutlined className="ml-8" />
                </Tooltip>
              </span>
            }
            value={data?.net_premium || '-'}
          />
        </Col>
        <Col span={8}>
          <Statistic
            // loading={loading}
            title={
              <span>
                Estimated Next Payment Date
                <Tooltip title="Total Commission Earned on the net premium for the product.">
                  <InfoCircleOutlined className="ml-8" />
                </Tooltip>
              </span>
            }
            value={data?.commission_earned || '-'}
          />
        </Col>
      </Row>
      <Divider />
      <div className="mt-24" />
    </Modal>
  );
};

export const NoteModal: React.FC<Props> = ({ isModalVisible, confirmHandler, cancelHandler, modalTitle, data }) => {
  return (
    <Modal
      title={modalTitle}
      visible={isModalVisible}
      footer={null}
      onCancel={cancelHandler}
      destroyOnClose
      width={800}
    >
      <Row gutter={16} className="">
        <div className="description" dangerouslySetInnerHTML={createMarkup(data?.notes)} />
      </Row>
    </Modal>
  );
};

interface BookingSourceModalProps {
  isModalVisible: boolean;
  cancelHandler: VoidFunction;
  onSuccess: VoidFunction;
}

export const BookingSourceModal: React.FC<BookingSourceModalProps> = ({ isModalVisible, cancelHandler, onSuccess }) => {
  const [source, setSource] = useState<string>(),
    [loading, setLoading] = useState<boolean>(false),
    [disabled, setDisabled] = useState<boolean>(false),
    createBookingSource = () => {
      if (!source?.length) {
        errorNotification('Name field cannot be empty')
        return;
      }
      setLoading(true)
      api.choices.postBookingSource({ name: source })
        .then((res) => {
          successNotification('Booking Source Created')
          onSuccess?.();
          cancelHandler?.()
        })
        .catch((err) => {
          errorHandler(err?.responce?.data)
        })
        .finally(() => {
          setLoading(false)
        })
    };

  useEffect(() => {
    return () => {
      setSource(undefined)
    }
  }, [])

  useEffect(() => {
    setDisabled(!source || !source?.length)
  }, [source])

  return (
    <Modal
      title={"Add Booking Source"}
      visible={isModalVisible}
      footer={null}
      onCancel={cancelHandler}
      destroyOnClose
      width={500}
    >
      <Row gutter={16} className="">
        <Input placeholder="Enter booking source" value={source} onChange={(e) => setSource(e?.target?.value)} />
      </Row>
      <Row gutter={16} className="modal-footer mt-36">
        <Col>
          <Button
            type="secondary"
            onClick={() => {
              cancelHandler();
            }}
          >
            CANCEL
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            loading={loading}
            onClick={createBookingSource}
            disabled={disabled}
          >
            CONFIRM
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomModal;

import { Col, Row } from "antd";
import moment from "moment";
import { objectFromStringAsKey, toTitleCase } from "../../utils/helper";

interface Props {
  keysToDisplay: any;
  data: any;
  route: any;
}

const EntityDetail: React.FC<Props> = ({ keysToDisplay, route, data }) => {
  const knownTypes = ['date', 'boolean', 'amount', 'link', 'amountWithDefault'];
  return <div className="client-details">
    <Row justify="space-between" gutter={{ xl: 24, lg: 24, md: 16 }}>
      {keysToDisplay?.map((item: any, index: any) => {
        return (
          <Col span={8} key={index}>
            {item?.map((item: any, indexTwo: any) => {
              return (
                <Row gutter={[0, 12]} key={indexTwo} style={{ marginTop: "10px" }}>
                  <Col span={8}>
                    <div className="key">
                        {item?.value || toTitleCase(item?.key)} {item?.tooltip}
                    </div>
                  </Col>
                  <Col span={16}>
                    <div className="value">
                      {item?.type === 'link' && item?.key && <a target="_blank" href={objectFromStringAsKey(data, item?.key)}>Click here</a>}
                      {item?.type === 'date' && moment(objectFromStringAsKey(data, item?.key))?.format('DD-MMM-YYYY')}
                      {item?.type === 'boolean' && (objectFromStringAsKey(data, item?.key) ? 'Yes' : 'No')}
                      {item?.type === 'amount' && (objectFromStringAsKey(data, item?.key))?.toLocaleString('en-IN')}  
                      {item?.type === 'amountWithDefault' && (objectFromStringAsKey(data, item?.key) || item?.defaultValue || 0)?.toLocaleString('en-IN')}
                      {knownTypes.every(type => item?.type !== type) && (objectFromStringAsKey(data, item?.key)  || '-')}
                      {item?.extraProp && <span className="ml-8">{item?.extraProp}</span>}
                    </div>
                  </Col>
                </Row>
              )
            })}
          </Col>
        )
      })}
    </Row>
  </div>
}

export default EntityDetail;
